<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getDepartmentError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('departments:departmentData')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step editable step="2">{{
                                $t('departments:departmentDatails')
                            }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="
                                                $t('departments:departmentData')
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6" sm="12" md="6" lg="6" xl="6"
                                        ><v-text-field
                                            :label="`${$t(
                                                'departments:fullName'
                                            )}*`"
                                            data-cy-departments="fullName"
                                            :error-messages="
                                                getDepartmentError.stepOne
                                                    .fields.name
                                            "
                                            v-model="getDepartmentModal.name"
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="6" sm="12" md="6" lg="6" xl="6"
                                        ><v-text-field
                                            :label="`${$t(
                                                'departments:shortName'
                                            )}`"
                                            data-cy-departments="shortName"
                                            v-model="
                                                getDepartmentModal.shortName
                                            "
                                        ></v-text-field
                                    ></v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="6"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                    >
                                        <v-select
                                            :items="statuses"
                                            :label="`${$t(
                                                'departments:status'
                                            )}*`"
                                            data-cy-departments="status"
                                            item-text="name"
                                            return-object
                                            :error-messages="
                                                getDepartmentError.stepOne
                                                    .fields.status
                                            "
                                            v-model="getDepartmentModal.status"
                                        ></v-select
                                    ></v-col>
                                    <v-col cols="6" sm="12" md="6" lg="6" xl="6"
                                        ><v-text-field
                                            :label="`${$t(
                                                'departments:location'
                                            )}`"
                                            data-cy-departments="location"
                                            v-model="
                                                getDepartmentModal.location
                                            "
                                        ></v-text-field></v-col
                                ></v-row>

                                <v-row>
                                    <v-col
                                        cols="6"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                    >
                                        <v-select
                                            data-cy-departments="category"
                                            :items="category"
                                            v-model="
                                                getDepartmentModal.category
                                            "
                                            :label="`${$t(
                                                'departments:category'
                                            )}`"
                                            item-value="field"
                                        >
                                            <template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        xl="12"
                                    >
                                        <v-textarea
                                            no-resize
                                            rows="1"
                                            :label="`${$t(
                                                'departments:comment'
                                            )}`"
                                            data-cy-departments="comment"
                                            v-model="
                                                getDepartmentModal.description
                                            "
                                        >
                                        </v-textarea> </v-col
                                ></v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2"
                                ><v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="
                                                $t(
                                                    'departments:responsiblePersons'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="6"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                    >
                                        <v-select
                                            :label="`${$t(
                                                'superiorDepartment'
                                            )}`"
                                            :items="allDepartments"
                                            data-cy-departments="superiorDepartment"
                                            item-text="name"
                                            item-value="_id"
                                            v-model="
                                                getDepartmentModal.superiorDepartment
                                            "
                                        ></v-select>
                                    </v-col>

                                    <v-col
                                        cols="6"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        xl="6"
                                    >
                                        <v-select
                                            :label="`${$t(
                                                'departments:leader'
                                            )}`"
                                            :items="menagerDepartment"
                                            data-cy-departments="leader"
                                            item-value="_id"
                                            v-model="getDepartmentModal.leader"
                                        >
                                            <template v-slot:selection="data">
                                                {{ data.item.name }}
                                                {{ data.item.lastname }}
                                            </template>
                                            <template v-slot:item="data">
                                                {{ data.item.name }}
                                                {{ data.item.lastname }}
                                            </template>
                                        </v-select></v-col
                                    ></v-row
                                >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        xl="12"
                                        ><v-select
                                            :label="`${$t(
                                                'departments:users'
                                            )}`"
                                            :items="getDepartmentsUsers"
                                            data-cy-departments="users"
                                            item-value="_id"
                                            multiple
                                            v-model="getDepartmentModal.users"
                                        >
                                            <template v-slot:selection="data">
                                                {{ data.item.name }}
                                                {{ data.item.lastname }},
                                            </template>
                                            <template v-slot:item="data">
                                                {{ data.item.name }}
                                                {{ data.item.lastname }}
                                            </template>
                                        </v-select></v-col
                                    ></v-row
                                ></v-stepper-content
                            >
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['departments', 'global'] },
    data() {
        return {
            date: '',
            step: 1,
            editable: true,
        }
    },

    computed: {
        ...mapGetters([
            'getDepartmentModal',
            'getDepartmentError',
            'getAllDepartments',
            'getDepartmentsUsers',
            'getDepartmentsStatuses',
            'getRegistryDictionaries',
        ]),
        statuses: {
            get() {
                return this.getDepartmentsStatuses.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                )
            },
        },
        allDepartments: {
            get() {
                return this.getAllDepartments.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                )
            },
        },
        menagerDepartment: {
            get() {
                return this.getDepartmentsUsers.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                )
            },
        },
        category: {
            get() {
                if (
                    this.getRegistryDictionaries &&
                    this.getRegistryDictionaries.category
                )
                    return this.getRegistryDictionaries.category.sort((a, b) =>
                        a.field > b.field ? 1 : -1
                    )
                else return []
            },
        },
    },
}
</script>
