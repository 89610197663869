<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <div class="mt-5 ma-5">
                        <p class="font-weight-bold d-flex flex-column headline">
                            {{ getDepartmentDetails.fullName }}
                            <span class="font-weight-regular">{{
                                getDepartmentDetails.shortName
                            }}</span>
                        </p>
                        <span
                            ><b>{{ `${$t('departments:leader')}:` }}</b>
                            {{
                                $get(
                                    getDepartmentDetails,
                                    'leader.name',
                                    'Brak'
                                )
                            }}
                            {{
                                $get(
                                    getDepartmentDetails,
                                    'leader.lastname',
                                    ''
                                )
                            }}</span
                        >
                    </div>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" md="12">
                        <Separator :text="$t('departments:departmentData')" />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                            readonly
                            :value="getDepartmentDetails.name"
                            :label="`${$t('departments:fullName')}`"
                            data-cy-details="fullName"
                        ></v-text-field
                    ></v-col>
                    <v-col cols="6" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                            readonly
                            :value="getDepartmentDetails.shortName"
                            :label="`${$t('departments:shortName')}`"
                            data-cy-details="shortName"
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                            readonly
                            :value="
                                $get(
                                    getDepartmentDetails,
                                    'status.name',
                                    $t('departments:empty')
                                )
                            "
                            :label="`${$t('departments:status')}`"
                            data-cy-details="status"
                        ></v-text-field
                    ></v-col>
                    <v-col cols="6" sm="12" md="6" lg="6" xl="6"
                        ><v-text-field
                            readonly
                            :value="getDepartmentDetails.location"
                            :label="`${$t('departments:location')}`"
                            data-cy-details="location"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                            readonly
                            :value="getDepartmentDetails.category"
                            :label="`${$t('departments:category')}`"
                            data-cy-details="category"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-textarea
                            no-resize
                            rows="1"
                            auto-grow
                            readonly
                            :value="getDepartmentDetails.description"
                            :label="`${$t('departments:comment')}`"
                            data-cy-details="comment"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="12">
                        <Separator
                            :text="$t('departments:responsiblePersons')"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="12" md="6" lg="6" xl="6" class="pb-0">
                        <v-text-field
                            readonly
                            :label="`${$t('departments:superiorDepartment')}`"
                            :value="
                                $get(
                                    getDepartmentDetails,
                                    'superiorDepartment.name'
                                )
                            "
                            data-cy-details="superiorDepartment"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="6" sm="12" md="6" lg="6" xl="6" class="pb-0">
                        <v-text-field
                            readonly
                            :label="`${$t('departments:leader')}`"
                            :value="setLeader(getDepartmentDetails.leader)"
                            data-cy-details="leader"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="pb-0"
                        ><v-text-field
                            readonly
                            :label="`${$t('departments:users')}`"
                            :value="setUsers(getDepartmentDetails.users)"
                            data-cy-details="users"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['departments'] },
    computed: {
        ...mapGetters(['getDepartmentDetails']),
    },
    methods: {
        setUsers(users) {
            return users && users.length > 0
                ? users
                      .map(item => `${item.name} ${item.lastname}, `)
                      .reduce((acc, item) => (acc += item))
                : ''
        },
        setLeader(leader) {
            return leader && leader.constructor === Object
                ? `${leader.name} ${leader.lastname}`
                : ''
        },
    },
}
</script>
