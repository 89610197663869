var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3","xl":"3","lg":"3","md":"5","sm":"12"}},[_c('v-row',[_c('div',{staticClass:"mt-5 ma-5"},[_c('p',{staticClass:"font-weight-bold d-flex flex-column headline"},[_vm._v(" "+_vm._s(_vm.getDepartmentDetails.fullName)+" "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.getDepartmentDetails.shortName))])]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('departments:leader')}:`))]),_vm._v(" "+_vm._s(_vm.$get( _vm.getDepartmentDetails, 'leader.name', 'Brak' ))+" "+_vm._s(_vm.$get( _vm.getDepartmentDetails, 'leader.lastname', '' )))])])])],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6","xl":"6","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('departments:departmentData')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getDepartmentDetails.name,"label":`${_vm.$t('departments:fullName')}`,"data-cy-details":"fullName"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getDepartmentDetails.shortName,"label":`${_vm.$t('departments:shortName')}`,"data-cy-details":"shortName"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.$get(
                                _vm.getDepartmentDetails,
                                'status.name',
                                _vm.$t('departments:empty')
                            ),"label":`${_vm.$t('departments:status')}`,"data-cy-details":"status"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getDepartmentDetails.location,"label":`${_vm.$t('departments:location')}`,"data-cy-details":"location"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getDepartmentDetails.category,"label":`${_vm.$t('departments:category')}`,"data-cy-details":"category"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-textarea',{attrs:{"no-resize":"","rows":"1","auto-grow":"","readonly":"","value":_vm.getDepartmentDetails.description,"label":`${_vm.$t('departments:comment')}`,"data-cy-details":"comment"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('departments:responsiblePersons')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"readonly":"","label":`${_vm.$t('departments:superiorDepartment')}`,"value":_vm.$get(
                                _vm.getDepartmentDetails,
                                'superiorDepartment.name'
                            ),"data-cy-details":"superiorDepartment"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"readonly":"","label":`${_vm.$t('departments:leader')}`,"value":_vm.setLeader(_vm.getDepartmentDetails.leader),"data-cy-details":"leader"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-text-field',{attrs:{"readonly":"","label":`${_vm.$t('departments:users')}`,"value":_vm.setUsers(_vm.getDepartmentDetails.users),"data-cy-details":"users"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }