var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getDepartmentError.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('departments:departmentData')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"editable":"","step":"2"}},[_vm._v(_vm._s(_vm.$t('departments:departmentDatails')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('departments:departmentData')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t(
                                            'departments:fullName'
                                        )}*`,"data-cy-departments":"fullName","error-messages":_vm.getDepartmentError.stepOne
                                                .fields.name},model:{value:(_vm.getDepartmentModal.name),callback:function ($$v) {_vm.$set(_vm.getDepartmentModal, "name", $$v)},expression:"getDepartmentModal.name"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t(
                                            'departments:shortName'
                                        )}`,"data-cy-departments":"shortName"},model:{value:(
                                            _vm.getDepartmentModal.shortName
                                        ),callback:function ($$v) {_vm.$set(_vm.getDepartmentModal, "shortName", $$v)},expression:"\n                                            getDepartmentModal.shortName\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-select',{attrs:{"items":_vm.statuses,"label":`${_vm.$t(
                                            'departments:status'
                                        )}*`,"data-cy-departments":"status","item-text":"name","return-object":"","error-messages":_vm.getDepartmentError.stepOne
                                                .fields.status},model:{value:(_vm.getDepartmentModal.status),callback:function ($$v) {_vm.$set(_vm.getDepartmentModal, "status", $$v)},expression:"getDepartmentModal.status"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t(
                                            'departments:location'
                                        )}`,"data-cy-departments":"location"},model:{value:(
                                            _vm.getDepartmentModal.location
                                        ),callback:function ($$v) {_vm.$set(_vm.getDepartmentModal, "location", $$v)},expression:"\n                                            getDepartmentModal.location\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-select',{attrs:{"data-cy-departments":"category","items":_vm.category,"label":`${_vm.$t(
                                            'departments:category'
                                        )}`,"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getDepartmentModal.category
                                        ),callback:function ($$v) {_vm.$set(_vm.getDepartmentModal, "category", $$v)},expression:"\n                                            getDepartmentModal.category\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-textarea',{attrs:{"no-resize":"","rows":"1","label":`${_vm.$t(
                                            'departments:comment'
                                        )}`,"data-cy-departments":"comment"},model:{value:(
                                            _vm.getDepartmentModal.description
                                        ),callback:function ($$v) {_vm.$set(_vm.getDepartmentModal, "description", $$v)},expression:"\n                                            getDepartmentModal.description\n                                        "}})],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                'departments:responsiblePersons'
                                            )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-select',{attrs:{"label":`${_vm.$t(
                                            'superiorDepartment'
                                        )}`,"items":_vm.allDepartments,"data-cy-departments":"superiorDepartment","item-text":"name","item-value":"_id"},model:{value:(
                                            _vm.getDepartmentModal.superiorDepartment
                                        ),callback:function ($$v) {_vm.$set(_vm.getDepartmentModal, "superiorDepartment", $$v)},expression:"\n                                            getDepartmentModal.superiorDepartment\n                                        "}})],1),_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-select',{attrs:{"label":`${_vm.$t(
                                            'departments:leader'
                                        )}`,"items":_vm.menagerDepartment,"data-cy-departments":"leader","item-value":"_id"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.lastname)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.lastname)+" ")]}}]),model:{value:(_vm.getDepartmentModal.leader),callback:function ($$v) {_vm.$set(_vm.getDepartmentModal, "leader", $$v)},expression:"getDepartmentModal.leader"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-select',{attrs:{"label":`${_vm.$t(
                                            'departments:users'
                                        )}`,"items":_vm.getDepartmentsUsers,"data-cy-departments":"users","item-value":"_id","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.lastname)+", ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.lastname)+" ")]}}]),model:{value:(_vm.getDepartmentModal.users),callback:function ($$v) {_vm.$set(_vm.getDepartmentModal, "users", $$v)},expression:"getDepartmentModal.users"}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }