<template>
    <v-container>
        <Header
            :registryTitle="getDepartmentsRegistry.name"
            :registryUrl="'departments'"
            :registryStatus="getDepartmentsStatuses"
            :actualStatus="getDepartmentDetails.status"
            @status-changed="changeStatus"
            :avatarData="getDepartmentDetails.createdBy"
        />
        <Main :activeTab="activeTab" class="p-relative">
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('global:edit')"
                    :open="open"
                    @close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        slot="buttons"
                        :action="'edit'"
                        @closeModal="closeModal"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-if="edit()"
                                v-on="on"
                                small
                                @click="openModal"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('departments:editDepartment') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from '../../../store/index'
import Information from './../../../components//Registries/Departments/Details/Information'
import Buttons from './../../../components/Registries/Departments/Modal/Buttons'
import AEContent from './../../../components/Registries/Departments/Modal/Content'

export default {
    i18nOptions: { namespaces: ['departments', 'global'] },
    components: {
        Information,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getDepartmentsStatuses',
            'getDepartmentDetails',
            'getDepartmentsRegistry',
        ]),
    },
    methods: {
        ...mapMutations(['setDepartmentModal', 'clearDepartmentModal']),
        ...mapActions([
            'fetchDepartmentsStatuses',
            'fetchAllDepartments',
            'fetchDepartmentsUsers',
            'updateDepartmentStatus',
        ]),
        closeModal() {
            this.clearDepartmentModal()
            this.open = false
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        changeStatus(status) {
            this.updateDepartmentStatus({
                status,
                id: this.getDepartmentDetails._id,
            })
        },
        async openModal() {
            await Promise.all([
                this.fetchDepartmentsStatuses(),
                this.fetchAllDepartments(),
                this.fetchDepartmentsUsers(),
            ])
            this.setDepartmentModal()

            this.open = true
        },
    },
    data: () => ({
        open: false,
        activeTab: 'information',
    }),
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchDepartment', { id: to.params.id, next })
    },
}
</script>
